<template>
  <div id="app">
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
      <div style="height: 20px"></div>
      <h2 class="ml-10">Diputados</h2>
      <h4 class="ml-10 font-weight-regular">Se renuevan 35 bancas</h4>
      <div style="height: 20px"></div>
      <v-slide-group multiple show-arrows :set="(provincia = 'bsas')">
        <v-row>
          <v-col
            v-for="resultado in resultados"
            :key="resultado.idAgrupacion"
          >
            <v-slide-item>
              <v-card
                color="#f2f2f0"
                class="mx-auto justify-center"
                min-height="280"
                min-width="280"
                width="288"
                style="background: rgb(232, 232, 232); background: linear-gradient(140deg, rgba(232, 232, 232, 1) 0%, rgba(229, 229, 238, 1) 28%, rgba(255, 255, 255, 1) 100%);">
                <v-card-title
                  style="font-size: 14px"
                  class="justify-center mb-n4"
                >
                  <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                </v-card-title>

                <div class="text-center" style="font-size: 12px">
                  <v-list-item-avatar
                    size="120"
                    color="grey darken-6"
                    class="mx-auto d-flex"
                  >
                    <v-img
                      class="elevation-6"
                      alt=""
                      :src="cambiaNombre(resultado.idAgrupacion, provincia)[1]"
                    />
                  </v-list-item-avatar>

                  <h2 style="font-size: 20px">
                    {{ cambiaNombre(resultado.idAgrupacion, provincia)[0] }}
                  </h2>
                  <p style="font-size: 18px">
                    {{ cambiaNombre(resultado.idAgrupacion, provincia)[2] }}
                  </p>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">ver más</span>
                    </template>
                    <span v-if="resultado.idAgrupacion == 43">
                      <b>Diego Cesar Santilli</b><br />
                      Maria Graciela Ocaña<br />
                      Facundo Manes<br />
                      Maria Marcela Campagnoli<br />
                      Juan Manuel Lopez<br />
                      Danya Tavela<br />
                      Gerardo Fabian Milman<br />
                      Maria Angel Sotolano<br />
                      Emilio Monzó<br />
                      Gabriela Gisel Besana<br />
                      Hernan Santiago Lombardi<br />
                      Margarita Stolbizer<br />
                      Alejandro Oscar Finocchiaro<br />
                      Maria Victoria Borrego<br />
                      Fabio Jose Quetglas<br />
                      Maria Florencia De Sensi<br />
                      Javier Sanchez Wrba<br />
                      Elsa Esther Llenderrozas<br />
                      Nelson Adrian Marino<br />
                      Patricia Maria Vasquez<br />
                      Jesus Cataldo Cariglino<br />
                      Maria Sofia De Hagen<br />
                      Walter Alejandro Di Giuseppe<br />
                      Josefina Mendoza<br />
                      Lucas Ariel Yacob<br />
                      Maria Margarita Djedjeian<br />
                      Pablo Adrian Juliano<br />
                      Mercedes Sanguineti<br />
                      Guillermo Manuel Sanchez Sterli<br />
                      Maria Jose Navajas<br />
                      Horacio Modesto Venier<br />
                      Rosalia De Los Angeles Fucello<br />
                      Mariano Luis Mugnolo<br />
                      Roxana Scort<br />
                      Emiliano Fernandez<br />
                    </span>
                    <span v-if="resultado.idAgrupacion == 44">
                      <b>Victoria Tolosa Paz</b><br />
                      Daniel Gustavo Gollan<br />
                      Marcela Fabiana Passo<br />
                      Sergio Omar Palazzo<br />
                      Agustina Lucrecia Propato<br />
                      Leopoldo Raul Guido Moreau<br />
                      Vanesa Raquel Siley<br />
                      Hugo Ruben Yasky<br />
                      Constanza Maria Alonso<br />
                      Julio Cesar Pereyra<br />
                      Monica Edith Litza<br />
                      Daniel Fernando Arroyo<br />
                      Brenda Magali Vargas Matyi<br />
                      Rogelio Iparraguirre<br />
                      Monica Fernanda Macha<br />
                      Daniel Alejandro Menendez<br />
                      Micaela Moran<br />
                      Jorge Walter Correa<br />
                      Claudia Alicia Bernazza<br />
                      Jorge Alberto D'Onofrio<br />
                      Daniela Emma Castro<br />
                      Marcelo Christian Vespertino Koenig<br />
                      Mariana Agustina Gras Buscetto<br />
                      Mariano Pinedo<br />
                      Fabiana Beatriz Bertino<br />
                      Angel Domingo Garcia<br />
                      Natalia Soledad Peluso<br />
                      Marcelo Ruben Lopez<br />
                      Araceli Viviana Bellotta<br />
                      Adrian Maximiliano Carril<br />
                      Julieta Ferrari<br />
                      Jorge Luis Laviuzza<br />
                      Maria Guillermina Eyras<br />
                      Cristian Javier Forlenza<br />
                      Florencia Actis Perinetto<br />
                    </span>
                    <span v-if="resultado.idAgrupacion == 41">
                      <b>Nicolas Del Caño</b><br />
                      Romina Del Pla<br />
                      Néstor Pitrola<br />
                      Monica Leticia Schlotthauer<br />
                      Juan Carlos Giordano<br />
                      Vilma Ripoll<br />
                      Alejandro Bodart<br />
                      Nathalia González Seligra<br />
                      Jorge Medina<br />
                      Gabriela De La Rosa<br />
                      Daniel Fernando Luna<br />
                      Paola Soledad Rodriguez Mariani<br />
                      Diego Gaston Martinez<br />
                      Natalia Aguilera<br />
                      Marcelo Daniel Gonzalez<br />
                      Maria Cristina Beitia Rocha<br />
                      Nicolás "Rulo" Mileo<br />
                      Nora Biaggio<br />
                      Edgardo Ernesto Reynoso<br />
                      Paula Anahi Alfaro<br />
                      Juan Carlos Beica<br />
                      Laura Bogado<br />
                      Jose Sava<br />
                      Pia Garralda<br />
                      Federico Casas<br />
                      Nancy Valeria Farias<br />
                      Sergio Almiron<br />
                      Norma Lezana<br />
                      Tomas Ernesto Feijoo<br />
                      Maria Ines Garcia<br />
                      Daniel Ernesto Sierra<br />
                      Maria Ines Demateis<br />
                      Hector Fabian Avendaño<br />
                      Mónica Aguirre<br />
                      Samuel Alfredo Mendez<br />
                    </span>
                    <span v-if="resultado.idAgrupacion == 40">
                      <b>José Luis Espert</b><br />
                      Carolina Rosana Piparo<br />
                      Hugo Eduardo Bontempo<br />
                      Karina Gabriela Garcia<br />
                      Luis Enrique Green<br />
                      Beatriz Eugenia Bulacios<br />
                      Osvaldo Alberto Simiele<br />
                      Natalia Melani Motyl<br />
                      Adolfo Miguel Laborde<br />
                      Daniela Virginia Delgado Luna<br />
                      Victorio Alejandro Ferraina<br />
                      Maria Soledad Bensusan<br />
                      Diego Alejandro Ulieldin<br />
                      Norma Silvia Mazzamuto<br />
                      Leandro Ceferino Hanon<br />
                      Luisa Bunge<br />
                      Adolfo Carlos Santos Güiraldes<br />
                      Vanesa Judith Bassani<br />
                      Alejandro Fabian Luchini<br />
                      Griselda Adriana Bin<br />
                      Luis Fernando Gallardo<br />
                      Maria Teresa Gonard<br />
                      Walter Mario Dotti<br />
                      Mariela Edith Sosa<br />
                      Ricardo Enrique Lopez Anadon<br />
                      Maria Belen Agudiez<br />
                      Fernando Antonio Gonzalez Prieto<br />
                      Rocio Carolina Solange Di Risio<br />
                      Diego Avancini<br />
                      Laura Veronica Novillo<br />
                      Diego Maximiliano Radibaniuk<br />
                      Juana Ines Sere<br />
                      Jose Manuel Jaugust<br />
                      Nancy Noelia Castellano<br />
                      Silvano Alejandro Gutierrez<br />
                    </span>
                    <span v-if="resultado.idAgrupacion == 45">
                      <b>Florencio Randazzo</b><br />
                      Carolina Castro<br />
                      Pulti Gustavo Arnaldo<br />
                      Iglesias Maria Valeria<br />
                      Verdini Ruben Adrian<br />
                      Rizzolo Julieta<br />
                      Cincunegui Juan De Dios<br />
                      Lopez Mariana<br />
                      Legnani Grotto Carlos Alberto<br />
                      Crespo Bjerg Maite<br />
                      Avalos Sergio Daniel<br />
                      Rodriguez Claudia Alejandra<br />
                      Blanco Rodrigo<br />
                      Capetillo Maria Teresa<br />
                      Fernandez Marcelo Herminio<br />
                      Lobianco Maria Lorena<br />
                      Christian Alberto Bonillo<br />
                      Martinez Camila Haydee<br />
                      Giorno Maximo Lucio<br />
                      Mainero Maria Grazia<br />
                      Fernandez Emiliano<br />
                      Huergo Cintia Sabrina<br />
                      Marrocco Marcelo Salvador<br />
                      Razzari Maria Teresa<br />
                      Falco Santiago Pedro<br />
                      Mendibil Mariana<br />
                      Perez Pablo Damian<br />
                      Garcia Araceli<br />
                      Poloni Esteban Ezequiel<br />
                      Mansa Camila Magali<br />
                      Vaio Adalberto Oscar<br />
                      Constant Maia<br />
                      Marco Juan Carlos<br />
                      Fernandez Estela Claudia<br />
                      Nunes Oscar Alejandro<br />
                    </span>

                    <span v-if="resultado.idAgrupacion == 38">
                      <b>Cynthia Liliana Hotton</b><br />
                      Gaston Oscar Bruno<br />
                      Maria Eugenia Santiago<br />
                      Daniel David Di Paolo<br />
                      Silvia Ada Gonzalez<br />
                      Jorge Martin Vivanco<br />
                      Maria E Roldan<br />
                      Ariel Fernando Squeff<br />
                      Maria Luisa D'Angelo Rodriguez<br />
                      Arturo Jaime Hotton Risler<br />
                      Carolina Elizabeth Kennedy<br />
                      Marcelo Fernando Resico<br />
                      Evangelina Norma Ibarbalz<br />
                      Luis José Liendro<br />
                      Dana Debora Carolina Casellas<br />
                      Aldo Norberto Castagna<br />
                      Vanesa Soledad Urueña<br />
                      Ricardo Fabian Bianchini<br />
                      Veronica Isabel Rea<br />
                      Oscar Bruno<br />
                      Fernanda Silvina Dominguez<br />
                      Ricardo Daniel Romero<br />
                      Melisa Capano<br />
                      Raul Abelardo Faure<br />
                      Elda Susana Pesoa<br />
                      Lucas Adrian Cassino<br />
                      Maria Pia Trebino<br />
                      Luis Omar Pussetto<br />
                      Maria Soledad Escalada<br />
                      Ruben Angel Aranda Fernandez<br />
                      Katerina Fabiola Parra Andia<br />
                      Edgardo Andres Madaria<br />
                      Silvina Ines Bergara<br />
                      Rene Fernando Tolosa<br />
                      Carla Elizabeth Nicolini<br />
                    </span>
                  </v-tooltip>

                  <!-- votos: {{ lista.votos }} -->
                  <!-- {{ resultado.idAgrupacion }} -->
                </div>

                <v-spacer />

                <v-card-actions class="ml-n2">
                  <v-progress-linear
                    :color="cambiaColor(resultado.idAgrupacion)"
                    style="position: absolute; bottom: 0"
                    :value="resultado.votosPorcentaje"
                    height="30"
                  >
                    <strong
                      style="font-size: 14px; color: #333; text-shadow: 1px 1px #fff;">
                      {{
                        cortaTitulo(
                          cambiaNombre(resultado.idAgrupacion, provincia)[3]
                        )
                      }}
                      {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong
                    >
                  </v-progress-linear>

                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-slide-item>
          </v-col>
        </v-row>
      </v-slide-group>
      <div style="height: 20px"></div>
      <div class="ml-11 mb-3" style="font-size: 13px">
        {{ poneMensaje(mesas, fecha_recuento) }}
      </div>
      <div style="height: 5px"></div>
    </v-sheet>

    <div style="height: 40px"></div>
  </div>
</template>

<script>
import { initializeApp } from "firebase/app";
import { getDatabase, ref, child, get } from "firebase/database";
import { getAnalytics, logEvent } from "firebase/analytics";

import configFirebase from "../configFirebase.js";

export default {
  name: "ModuloCardsListas",

  data() {
    return {
      resultados: [],
      fecha_recuento: [],
      mesas: [],
      mesa: "",
      fecha: "",
    };
  },
  mounted() {
    this.carga_datos();
    //this.carga_datos_update()
    //setInterval(this.carga_datos(), 1000);
  },
  watch: {
    "$route.path": function () {
      this.carga_datos()
    },
  },
  methods: {
    carga_datos(){
      //console.log("cargando bsas");
        const app = initializeApp(configFirebase);
        const dbRef = ref(getDatabase(app));

        const analytics = getAnalytics();
        logEvent(analytics, "page_view");

        get(child(dbRef, `bsas2/valoresTotalizadosPositivos`))
          .then((snapshot) => {
            this.resultados = snapshot.val();
          })
          .catch((error) => {
            console.error(error);
          });

        get(child(dbRef, `bsas2/fechaTotalizacion`))
          .then((snapshot) => {
            //console.log(snapshot.val());
            this.fecha_recuento = snapshot.val();
          })
          .catch((error) => {
            console.error(error);
          });

        get(child(dbRef, `bsas2/estadoRecuento/mesasTotalizadasPorcentaje`))
          .then((snapshot) => {
            this.mesas = snapshot.val();
          })
          .catch((error) => {
            console.error(error);
          });
        
        

        return "";
    },
    carga_datos_update: function () {
      setInterval(() => {
        this.carga_datos();
      }, 60000);
    },

    cortaTitulo(agrupacion) {
      let nagrupacion = "";
      switch (agrupacion) {
        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
          nagrupacion = "FIT - UNIDAD";
          break;
        case "CORRIENTE DE PENSAMIENTO BONAERENSE":
          nagrupacion = "CTE DE PENSAMIENTO BONAERENSE";
          break;
        case "CHACO CAMBIA + JUNTOS POR EL CAMBIO":
          nagrupacion = "CHACO CAMBIA + JXC";
          break;
        case "COALICIÓN CÍVICA - AFIRMACIÓN PARA UNA REPÚBLICA IGUALITARIA (ARI)":
          nagrupacion = "COALICIÓN CÍVICA - ARI";
          break;
        case "SOMOS ENERGÍA PARA RENOVAR SANTA CRUZ -SER-":
          nagrupacion = "SOMOS ENERGÍA P R SANTA CRUZ";
          break;
        case "JUNTOS POR EL CAMBIO TIERRA DEL FUEGO":
          nagrupacion = "JXC TIERRA DEL FUEGO";
          break;
        default:
          nagrupacion = agrupacion;
          break;
      }
      return nagrupacion;
    },
    poneMensaje(total, fecha) {
      let totalMensaje = "";
      if (fecha != "") {
        return (totalMensaje = `Mesas escrutadas: ${total}% actualizado: ${new Date(
          fecha
        ).toLocaleTimeString()}`);
      }
      return (totalMensaje = "Cargando datos");
    },

    cambiaNombre(nombre, provincia) {
      let agrupacion = "";
      let candidato = "";
      let candidato2 = "";
      let fotoLista = "";

      //console.log(nombre);
      //buenos aires

      switch (provincia) {
        case "bsas":
          /*buenos aires*/
          switch (nombre) {
            case "38":
              agrupacion = "+ VALORES";
              candidato = "Cynthia Liliana Hotton";
              candidato2 = "Gaston Oscar Bruno";
              fotoLista = "/img/bsas_cynthia_hotton.jpg";
              break;

            case "40":
              agrupacion = "AVANZA LIBERTAD";
              candidato = "José Luis Espert";
              candidato2 = "Carolina Rosana Piparo";
              fotoLista = "/img/bsas_jose_luis_espert.jpg";
              break;

            case "45":
              agrupacion = "FRENTE VAMOS CON VOS";
              candidato = "Florencio Randazzo";
              candidato2 = "Carolina Castro";
              fotoLista = "/img/bsas_florencio_randazzo.jpg";
              break;

            case "43":
              agrupacion = "JUNTOS";
              candidato = "Diego Cesar Santilli";
              candidato2 = "Maria Graciela Ocaña";
              fotoLista = "/img/bsas_diego_santilli.jpg";
              break;

            case "41":
              agrupacion = "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD";
              candidato = "Nicolas Del Caño";
              candidato2 = "Romina Del Pla";
              fotoLista = "/img/bsas_nicolas_del_cano.jpg";
              break;

            case "44":
              agrupacion = "FRENTE DE TODOS";
              candidato = "Victoria Tolosa Paz";
              candidato2 = "Daniel Gustavo Gollan";
              fotoLista = "/img/bsas_victoria_tolosa_paz.jpg";
              break;
          }
          break;

        default:
          candidato = nombre;
          candidato2 = "";

          break;
      }

      if (fotoLista == "") {
        fotoLista = "/img/persona.png";
      }
      return [candidato, fotoLista, candidato2, agrupacion];
      // alert(nombre);
    },

    verifica_porcenteje(porcentaje_partido) {
      if (porcentaje_partido == 100) {
        return "";
      } else {
        return porcentaje_partido + " %";
      }
    },

    cambiaColor(idAgrupacion) {
      let agrupacionColor = "";
      switch (idAgrupacion) {
        case "40":
          agrupacionColor = "rgba(78, 102, 173)";
          break;
        case "41":
          agrupacionColor = "rgba(233, 23, 48)"; //FIT
          break;
        case "44":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
          break;
        case "43":
          agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
          break;
        case "45":
          agrupacionColor = "rgba(0, 176, 139)";
          break;
        default:
          agrupacionColor = "rgba(140, 147, 156)";
          break;
      }
      return agrupacionColor;
    },
  },
};
</script>
