<template>
  <div>




    <h2 class="text-center titulo-provincia" style="font-size: 2.5rem; color:#0289cb;">Buenos Aires</h2>
    <bsas></bsas>
    <h2 class="text-center titulo-provincia" style="font-size: 2.5rem; color:#0289cb;">Ciudad de Buenos Aires</h2>
    <caba></caba>
    <h2 class="text-center titulo-provincia" style="font-size: 2.5rem; color:#0289cb;">Córdoba</h2>
    <cordoba></cordoba>
    <h2 class="text-center titulo-provincia" style="font-size: 2.5rem; color:#0289cb;">Santa Fe</h2>
    <santafe></santafe>
    <h2 class="text-center titulo-provincia" style="font-size: 2.5rem; color:#0289cb;">Mendoza</h2>
    <mendoza></mendoza>
    <h2 class="text-center titulo-provincia" style="font-size: 2.5rem; color:#0289cb;">Tucumán</h2>
    <tucuman></tucuman>
    <h2 class="text-center titulo-provincia" style="font-size: 2.5rem; color:#0289cb;">Catamarca</h2>
    <catamarca></catamarca>
    <h2 class="text-center titulo-provincia" style="font-size: 2.5rem; color:#0289cb;">Corrientes</h2>
    <corrientes></corrientes>
    <h2 class="text-center titulo-provincia" style="font-size: 2.5rem; color:#0289cb;">La Pampa</h2>
    <lapampa></lapampa>
    <h2 class="text-center titulo-provincia" style="font-size: 2.5rem; color:#0289cb;">Chubut</h2>
    <chubut></chubut>
    <h2 class="text-center titulo-provincia" style="font-size: 2.5rem; color:#0289cb;">Entre Ríos</h2>
    <entrerios></entrerios>
    <h2 class="text-center titulo-provincia" style="font-size: 2.5rem; color:#0289cb;">Chaco</h2>
    <chaco></chaco>
    <h2 class="text-center titulo-provincia" style="font-size: 2.5rem; color:#0289cb;">Jujuy</h2>
    <jujuy></jujuy>
    <h2 class="text-center titulo-provincia" style="font-size: 2.5rem; color:#0289cb;">Misiones</h2>
    <misiones></misiones>
    <h2 class="text-center titulo-provincia" style="font-size: 2.5rem; color:#0289cb;">Neuquén</h2>
    <neuquen></neuquen>
    <h2 class="text-center titulo-provincia" style="font-size: 2.5rem; color:#0289cb;">Salta</h2>
    <salta></salta>
    <h2 class="text-center titulo-provincia" style="font-size: 2.5rem; color:#0289cb;">San Juan</h2>
    <sanjuan></sanjuan>
    <h2 class="text-center titulo-provincia" style="font-size: 2.5rem; color:#0289cb;">San Luis</h2>
    <sanluis></sanluis>
    <h2 class="text-center titulo-provincia" style="font-size: 2.5rem; color:#0289cb;">Santa Cruz</h2>
    <santacruz></santacruz>
    <h2 class="text-center titulo-provincia" style="font-size: 2.5rem; color:#0289cb;">Santiago del Estero</h2>
    <santiago></santiago>
    <h2 class="text-center titulo-provincia" style="font-size: 2.5rem; color:#0289cb;">Formosa</h2>
    <formosa></formosa>
    <h2 class="text-center titulo-provincia" style="font-size: 2.5rem; color:#0289cb;">La Rioja</h2>
    <larioja></larioja>
    <h2 class="text-center titulo-provincia" style="font-size: 2.5rem; color:#0289cb;">Río Negro</h2>
    <rionegro></rionegro>
    <h2 class="text-center titulo-provincia" style="font-size: 2.5rem; color:#0289cb;">Tierra del Fuego</h2>
    <tdelfuego></tdelfuego>
  </div>
</template>
<script>
    import bsas from '../components/bsas';
    import caba from '../components/caba';
    import cordoba from '../components/cordoba';
    import santafe from '../components/santafe';
    import mendoza from '../components/mendoza';
    import tucuman from '../components/tucuman';
    import catamarca from '../components/catamarca';
    import corrientes from '../components/corrientes';
    import lapampa from '../components/lapampa';
    import chubut from '../components/chubut';
    import entrerios from '../components/entrerios';
    import chaco from '../components/chaco';
    import jujuy from '../components/jujuy';
    import misiones from '../components/misiones';
    import neuquen from '../components/neuquen';
    import salta from '../components/salta';
    import sanjuan from '../components/sanjuan';
    import sanluis from '../components/sanluis';
    import santacruz from '../components/santacruz';
    import santiago from '../components/santiago';
    import formosa from '../components/formosa';
    import larioja from '../components/larioja';
    import rionegro from '../components/rionegro';
    import tdelfuego from '../components/tdelfuego';





    export default {
        name: 'TodasProvincias',
        components: {
            bsas,
            caba,
            cordoba,
            santafe,
            mendoza,
            tucuman,
            catamarca,
            corrientes,
            lapampa,
            chubut,
            entrerios,
            chaco,
            jujuy,
            misiones,
            neuquen,
            salta,
            sanjuan,
            sanluis,
            santacruz,
            santiago,
            formosa,
            larioja,
            rionegro,
            tdelfuego,
        },
    };
</script>
<style scoped>
    .titulo {
        color: #333;
        font-family: IBM Plex Sans, Helvetica Neue, Arial, sans-serif;
        font-size: 3.75em;
        font-weight: 700;
    }
    
    .titulo-provincia {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
    }
</style>