<template>
  <div id="app">
    

    
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
      <div style="height: 20px"></div>
      <h2 class="ml-10">Senadores</h2>
      <h4 class="ml-10 font-weight-regular">Se renuevan 3 bancas</h4>
      <div style="height: 20px"></div>
      <v-slide-group multiple show-arrows :set="(provincia = 'santafe_s')">
        <v-row>
          <v-col v-for="resultado in resultados1" :key="resultado.idAgrupacion">
            <v-slide-item>
              <v-card
                color="#f2f2f0"
                class="mx-auto justify-center"
                min-height="280"
                min-width="280"
                width="288"
                style="background: rgb(232, 232, 232); background: linear-gradient(140deg, rgba(232, 232, 232, 1) 0%, rgba(229, 229, 238, 1) 28%, rgba(255, 255, 255, 1) 100%);">
                <v-card-title
                  style="font-size: 14px"
                  class="justify-center mb-n4"
                >
                  <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                </v-card-title>

                <div
                  
                  class="text-center"
                  style="font-size: 12px"
                >
                  <v-list-item-avatar
                    
                    size="120"
                    color="grey darken-6"
                    class="mx-auto d-flex"
                  >
                    <v-img
                      class="elevation-6"
                      alt=""
                      :src="
                        cambiaNombre(resultado.idAgrupacion, provincia)[1]
                      "
                    />
                  </v-list-item-avatar>

               

                  <h2 style="font-size:20px;">
                    {{ cambiaNombre(resultado.idAgrupacion, provincia)[0] }}                    
                  </h2>
                  <p style="font-size:18px;">
                    {{ cambiaNombre(resultado.idAgrupacion, provincia)[2] }}
                  </p>
                  <!-- votos: {{ lista.votos }} -->
                  <!-- {{ resultado.idAgrupacion }} -->
                </div>

                <v-spacer />

                <v-card-actions class="ml-n2">
                  <v-progress-linear
                    :color="cambiaColor(resultado.idAgrupacion)"
                    style="position: absolute; bottom: 0"
                    :value="resultado.votosPorcentaje"
                    height="30"
                  >
                    <strong style="font-size: 14px; color: #333; text-shadow: 1px 1px #fff;">{{ cortaTitulo(
                          cambiaNombre(resultado.idAgrupacion, provincia)[3]
                        ) }}
                      {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong
                    >
                  </v-progress-linear>

                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-slide-item>
          </v-col>
        </v-row>
      </v-slide-group>
      <div style="height: 20px"></div>
      <div class="ml-11" style="font-size: 13px">
          {{ poneMensaje(mesas, fecha_recuento) }} 
      </div>
      <div style="height: 15px"></div>
    </v-sheet>

    <div style="height: 40px"></div>

    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
      <div style="height: 20px"></div>
      <h2 class="ml-10">Diputados</h2>
      <h4 class="ml-10 font-weight-regular">Se renuevan 9 bancas</h4>
      <div style="height: 20px"></div>
      <v-slide-group multiple show-arrows :set="(provincia = 'santafe')">
        <v-row>
          <v-col v-for="resultado in resultados2" :key="resultado.idAgrupacion">
            <v-slide-item>
              <v-card
                color="#f2f2f0"
                class="mx-auto justify-center"
                min-height="280"
                min-width="280"
                width="288"
                style="background: rgb(232, 232, 232); background: linear-gradient(140deg, rgba(232, 232, 232, 1) 0%, rgba(229, 229, 238, 1) 28%, rgba(255, 255, 255, 1) 100%);">
                <v-card-title
                  style="font-size: 14px"
                  class="justify-center mb-n4"
                >
                  <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                </v-card-title>

                <div
                 
                  class="text-center"
                  style="font-size: 12px"
                >
                  <v-list-item-avatar
                  
                    size="120"
                    color="grey darken-6"
                    class="mx-auto d-flex"
                  >
                    <v-img
                      class="elevation-6"
                      alt=""
                      :src="
                        cambiaNombre(resultado.idAgrupacion, provincia)[1]
                      "
                    />
                  </v-list-item-avatar>

               

                  <h2 style="font-size:20px;">
                    {{ cambiaNombre(resultado.idAgrupacion, provincia)[0] }}                    
                  </h2>
                  <p style="font-size:18px;">
                    {{ cambiaNombre(resultado.idAgrupacion, provincia)[2] }}
                  </p>
                  <!-- votos: {{ lista.votos }} -->
                  <!-- {{ resultado.idAgrupacion }} -->
                </div>

                <v-spacer />

                <v-card-actions class="ml-n2">
                  <v-progress-linear
                    :color="cambiaColor(resultado.idAgrupacion)"
                    style="position: absolute; bottom: 0"
                    :value="resultado.votosPorcentaje"
                    height="30"
                  >
                    <strong style="font-size: 14px; color: #333; text-shadow: 1px 1px #fff;">{{ cortaTitulo(
                          cambiaNombre(resultado.idAgrupacion, provincia)[3]
                        ) }}
                      {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong
                    >
                  </v-progress-linear>

                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-slide-item>
          </v-col>
        </v-row>
      </v-slide-group>
      <div style="height: 20px"></div>
      <div class="ml-11" style="font-size: 13px">
          {{ poneMensaje(mesas, fecha_recuento) }}  
      </div>
      <div style="height: 15px"></div>
    </v-sheet>

    <div style="height: 40px"></div>

  </div>
</template>

<script>
import { initializeApp } from "firebase/app";
import { getDatabase, ref, child, get } from "firebase/database";
import configFirebase from "../configFirebase.js";

export default {
  name: "ModuloCardsListas",

  data() {
    return {
      resultados1: [],     
      resultados2: [], 
      fecha_recuento: [],
      mesas: [],
      mesa: "",
      fecha: "",
    };
  },
  mounted() {
    this.carga_datos();
    //this.carga_datos_update()
  },
  watch: {
    "$route.path": function () {
      this.carga_datos();
    },
  },
  methods: {
    carga_datos() {
      const app = initializeApp(configFirebase);
      const dbRef = ref(getDatabase(app));

      get(child(dbRef, `santafe_s2/valoresTotalizadosPositivos`))
        .then((snapshot) => {
          this.resultados1 = snapshot.val();
        })
        .catch((error) => {
          console.error(error);
        });


      get(child(dbRef, `santafe2/valoresTotalizadosPositivos`))
        .then((snapshot) => {
          this.resultados2 = snapshot.val();
        })
        .catch((error) => {
          console.error(error);
        });

     

      get(child(dbRef, `santafe2/fechaTotalizacion`))
        .then((snapshot) => {
          //console.log(snapshot.val());
          this.fecha_recuento = snapshot.val();
        })
        .catch((error) => {
          console.error(error);
        });

      get(
        child(
          dbRef,
          `santafe2/estadoRecuento/mesasTotalizadasPorcentaje`
        )
      )
        .then((snapshot) => {
          this.mesas = snapshot.val();
        })
        .catch((error) => {
          console.error(error);
        });
      return "";
    },
    carga_datos_update: function () {
      setInterval(() => {
        this.carga_datos();
      }, 60000);
    },
    cortaTitulo(agrupacion) {
      let nagrupacion = "";
      switch (agrupacion) {
        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
          nagrupacion = "FIT - UNIDAD";
          break;
        case "CORRIENTE DE PENSAMIENTO BONAERENSE":
          nagrupacion = "CTE DE PENSAMIENTO BONAERENSE";
          break;
        case "CHACO CAMBIA + JUNTOS POR EL CAMBIO":
          nagrupacion = "CHACO CAMBIA + JXC";
          break;
        case "COALICIÓN CÍVICA - AFIRMACIÓN PARA UNA REPÚBLICA IGUALITARIA (ARI)":
          nagrupacion = "COALICIÓN CÍVICA - ARI";
          break;
        case "SOMOS ENERGÍA PARA RENOVAR SANTA CRUZ -SER-":
          nagrupacion = "SOMOS ENERGÍA P R SANTA CRUZ";
          break;
        case "JUNTOS POR EL CAMBIO TIERRA DEL FUEGO":
          nagrupacion = "JXC TIERRA DEL FUEGO";
          break;
        case "UNITE POR LA LIBERTAD Y LA DIGNIDAD":
          nagrupacion = "LIBERTAD Y DIGNIDAD";
          break;
        default:
          nagrupacion = agrupacion;
          break;
      }
      return nagrupacion;
    },
    poneMensaje(total, fecha) {
      let totalMensaje = "";
      if (fecha != "") {
        return (totalMensaje = `Mesas escrutadas: ${total}% actualizado: ${new Date(
          fecha
        ).toLocaleTimeString()}`);
      }
      return (totalMensaje = "Cargando datos");
    },

    cambiaNombre(nombre, provincia) {
      let agrupacion = "";
      let candidato = "";
      let candidato2 = "";
      let fotoLista = "";

      //console.log(nombre);
      //buenos aires

      switch (provincia) {
        case "santafe":
          /*santa fe*/
          switch (nombre) {
            case "221":
              agrupacion = "FRENTE AMPLIO PROGRESISTA";
              candidato = "Monica Fein";
              candidato2 = "Gonzalo  Saglione";
              fotoLista = "/img/santafe_monica_fein.jpg";
              break;

            case "222":
              agrupacion = "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD";
              candidato = "Carla Deiana";
              candidato2 = "Octavio Crivaro";
              fotoLista = "/img/santafe_carla_deiana.jpg";
              break;

            case "228":
              agrupacion = "FRENTE DE TODOS";
              candidato = "Roberto Mario Mirabella";
              candidato2 = "Magali Mastaler";
              fotoLista = "/img/santafe_roberto_mario_mirabella.jpg";
              break;

            case "223":
              agrupacion = "JUNTOS POR EL CAMBIO";
              candidato = "Mario Domingo Barletta";
              candidato2 = "Victoria Tejeda";
              fotoLista = "/img/santafe_mario_domingo_barletta.jpg";
              break;

            case "226":
              agrupacion = "PODEMOS";
              candidato = "Patricia Silva De Cattaneo";
              candidato2 = "Nahuel Rene Bay";
              fotoLista = "/img/santafe_patricia_silva_de_cattaneo.jpg";
              break;

            case "224":
              agrupacion = "PRIMERO SANTA FE";
              candidato = "Mario Atilio Deschi";
              candidato2 = "Gabriela Liliana Arrieta";
              fotoLista = "/img/santafe_mario_atilio_deschi.jpg";
              break;

            case "227":
              agrupacion = "SOBERANIA POPULAR";
              candidato = "Carlos Del Frade";
              candidato2 = "Eliana Tedini";
              fotoLista = "/img/santafe_carlos_del_frade.jpg";
              break;

            case "225":
              agrupacion = "SOMOS FUTURO";
              candidato = "Coty Hernandez";
              candidato2 = "Belu Gomez";
              fotoLista = "/img/santafe_coty_hernandez.jpg";
              break;

            case "216":
              agrupacion = "UNITE POR LA LIBERTAD Y LA DIGNIDAD";
              candidato = "Alejandra 'Locomotora' Oliveras";
              candidato2 = "Cristian Fernando Hoffmann";
              fotoLista = "/img/santafe_alejandra_oliveras.jpg";
              break;
          }
          break;
        case "santafe_s":
          switch (nombre) {
            case "221":
              agrupacion = "FRENTE AMPLIO PROGRESISTA";
              candidato = "Clara Garcia";
              candidato2 = "Paco  Garibaldi";
              fotoLista = "/img/santafe_clara_garcia.jpg";
              break;
            case "222":
              agrupacion = "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD";
              candidato = "Irene Gamboa";
              candidato2 = "Luciano Caceres";
              fotoLista = "/img/santafe_irene_gamboa.jpg";
              break;
            case "228":
              agrupacion = "FRENTE DE TODOS";
              candidato = "Marcelo Nestor Lewandowski";
              candidato2 = "Maria De Los Angeles Sacnun";
              fotoLista = "/img/santafe_marcelo_nestor_lewandowski.jpg";
              break;
            case "223":
              agrupacion = "JUNTOS POR EL CAMBIO";
              candidato = "Carolina Losada";
              candidato2 = "Dionisio Fernando Scarpin";
              fotoLista = "/img/santafe_carolina_losada.jpg";
              break;
            case "226":
              agrupacion = "PODEMOS";
              candidato = "Juan Domingo Argañaraz";
              candidato2 = "Maria Ines D'Angelo";
              fotoLista = "/img/santafe_juan_domingo_argañaraz.jpg";
              break;
            case "224":
              agrupacion = "PRIMERO SANTA FE";
              candidato = "Jorge Rosario Boasso";
              candidato2 = "Maria Virginia Borga";
              fotoLista = "/img/santafe_jorge_rosario_boasso.jpg";
              break;
            case "227":
              agrupacion = "SOBERANIA POPULAR";
              candidato = "Mercedes Meier";
              candidato2 = "Javier Barbona";
              fotoLista = "/img/santafe_mercedes_meier.jpg";
              break;
            case "225":
              agrupacion = "SOMOS FUTURO";
              candidato = "Patricia  Frausin";
              candidato2 = "Bichi Sfiligoy";
              fotoLista = "/img/santafe_patricia_frausin.jpg";
              break;
            case "216":
              agrupacion = "UNITE POR LA LIBERTAD Y LA DIGNIDAD";
              candidato = "Betina Ines Florito";
              candidato2 = "Ignacio De Biasi";
              fotoLista = "/img/santafe_betina_ines_florito.jpg";
              break;
          }
          break;

        

        default:
          candidato = nombre;
          candidato2 = "";

          break;
      }

      if (fotoLista == "") {
        fotoLista = "/img/persona.png";
      }
      return [candidato, fotoLista, candidato2, agrupacion];
      // alert(nombre);
    },

    verifica_porcenteje(porcentaje_partido) {
      if (porcentaje_partido == 100) {
        return "";
      } else {
        return porcentaje_partido + " %";
      }
    },

    cambiaColor(idAgrupacion) {
      let agrupacionColor = "";
      switch (idAgrupacion) {
        case "7":
          agrupacionColor = "rgba(89, 46, 107)";
          break;
        case "8":
          agrupacionColor = "rgb(204, 0, 0)";
          break;
        case "9":
          agrupacionColor = "rgba(244, 2, 46)";
          break;
        case "11":
          agrupacionColor = "rgb(228, 114, 9)";
          break;

        case "14":
          agrupacionColor = "rgba(78, 102, 173)";
          break;
        case "27":
          agrupacionColor = "rgba(253, 1, 0)";
          break;
        case "40":
          agrupacionColor = "rgba(78, 102, 173)";
          break;
        case "13":
          agrupacionColor = "rgba(233, 23, 48)"; //FIT
          break;
        case "41":
          agrupacionColor = "rgba(233, 23, 48)"; //FIT
          break;
        case "98":
          agrupacionColor = "rgba(233, 23, 48)"; //FIT
          break;
        case "136":
          agrupacionColor = "rgba(233, 23, 48)"; //FIT
          break;
        case "114":
          agrupacionColor = "rgba(233, 23, 48)"; //FIT
          break;
        case "150":
          agrupacionColor = "rgba(233, 23, 48)"; //FIT
          break;
        case "155":
          agrupacionColor = "rgba(233, 23, 48)"; //FIT
          break;
        case "161":
          agrupacionColor = "rgba(233, 23, 48)"; //FIT
          break;
        case "168":
          agrupacionColor = "rgba(233, 23, 48)"; //FIT
          break;
        case "183":
          agrupacionColor = "rgba(233, 23, 48)"; //FIT
          break;
        case "189":
          agrupacionColor = "rgba(233, 23, 48)"; //FIT
          break;
        case "195":
          agrupacionColor = "rgba(233, 23, 48)"; //FIT
          break;
        case "202":
          agrupacionColor = "rgba(233, 23, 48)"; //FIT
          break;
        case "210":
          agrupacionColor = "rgba(233, 23, 48)"; //FIT
          break;
        case "215":
          agrupacionColor = "rgba(233, 23, 48)"; //FIT
          break;
        case "222":
          agrupacionColor = "rgba(233, 23, 48)"; //FIT
          break;
        case "243":
          agrupacionColor = "rgba(233, 23, 48)"; //FIT
          break;

        case "44":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
          break;
        case "12":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
          break;
        case "96":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
          break;
        case "125":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
          break;
        case "134":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
          break;
        case "115":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
          break;
        case "117":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
          break;
        case "142":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
          break;
        case "145":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
          break;
        case "148":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
          break;
        case "153":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
          break;
        case "160":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
          break;
        case "169":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
          break;
        case "176":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
          break;
        case "182":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
          break;
        case "187":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
          break;
        case "198":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
          break;
        case "201":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
          break;
        case "214":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
          break;
        case "228":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
          break;
        case "250":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
          break;
        case "241":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
          break;

        case "137":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE SOCIALISTA
          break;
        case "151":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE SOCIALISTA
          break;
        case "151":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE SOCIALISTA
          break;
        case "179":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE SOCIALISTA
          break;
        case "185":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE SOCIALISTA
          break;

        case "43":
          agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
          break;

        case "11":
          agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
          break;
        case "97":
          agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
          break;
        case "113":
          agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
          break;
        case "154":
          agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
          break;
        case "190":
          agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
          break;
        case "199":
          agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
          break;
        case "223":
          agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
          break;
        case "236":
          agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
          break;
        case "194":
          agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
          break;
        case "135":
          agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
          break;
        case "242":
          agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
          break;
        case "251":
          agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
          break;
        case "170":
          agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
          break;
        case "118":
          agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
          break;
        case "143":
          agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
          break;
        case "146":
          agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
          break;
        case "126":
          agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
          break;
        case "184":
          agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
          break;
        case "209":
          agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
          break;
        case "213":
          agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
          break;
        case "159":
          agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
          break;

        case "45":
          agrupacionColor = "rgba(0, 176, 139)";
          break;
        case "127":
          agrupacionColor = "rgb(91, 159, 130)"; // VAMOS CON VOS
          break;

        case "119":
          agrupacionColor = "rgb(91, 159, 130)"; // VAMOS CON VOS
          break;
        case "119":
          agrupacionColor = "rgb(91, 159, 130)"; // VAMOS CON VOS
          break;

        default:
          agrupacionColor = "rgba(140, 147, 156)";
          break;
      }
      return agrupacionColor;
    },
  },
};
</script>
