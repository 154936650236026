<template>
  <div id="app">
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
      <div style="height: 20px"></div>
      <h2 class="ml-10">Senadores</h2>
      <h4 class="ml-10 font-weight-regular">Se renuevan 3 bancas</h4>
      <div style="height: 20px"></div>
      <v-slide-group multiple show-arrows :set="(provincia = 'cordoba_s')">
        <v-row>
          <v-col v-for="resultado in resultados1" :key="resultado.idAgrupacion">
            <v-slide-item>
              <v-card
                color="#f2f2f0"
                class="mx-auto justify-center"
                min-height="280"
                min-width="280"
                width="288"
                style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                "
              >
                <v-card-title
                  style="font-size: 14px"
                  class="justify-center mb-n4"
                >
                  <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                </v-card-title>

                <div class="text-center" style="font-size: 12px">
                  <v-list-item-avatar
                    size="120"
                    color="grey darken-6"
                    class="mx-auto d-flex"
                  >
                    <v-img
                      class="elevation-6"
                      alt=""
                      :src="cambiaNombre(resultado.idAgrupacion, provincia)[1]"
                    />
                  </v-list-item-avatar>

                  <h2 style="font-size: 20px">
                    {{ cambiaNombre(resultado.idAgrupacion, provincia)[0] }}
                  </h2>
                  <p style="font-size: 18px">
                    {{ cambiaNombre(resultado.idAgrupacion, provincia)[2] }}
                  </p>
                  <!-- votos: {{ lista.votos }} -->
                  <!-- {{ resultado.idAgrupacion }} -->
                </div>

                <v-spacer />

                <v-card-actions class="ml-n2">
                  <v-progress-linear
                    :color="cambiaColor(resultado.idAgrupacion)"
                    style="position: absolute; bottom: 0"
                    :value="resultado.votosPorcentaje"
                    height="30"
                  >
                   
                      <strong style="font-size: 14px; color: #333; text-shadow: 1px 1px #fff;">{{
                        cortaTitulo(
                          cambiaNombre(resultado.idAgrupacion, provincia)[3]
                        )
                      }}
                      {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong
                    >
                  </v-progress-linear>

                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-slide-item>
          </v-col>
        </v-row>
      </v-slide-group>
      <div style="height: 20px"></div>
      <div class="ml-11" style="font-size: 13px">
         {{ poneMensaje(mesas, fecha_recuento) }} 
      </div>
      <div style="height: 15px"></div>
    </v-sheet>

    <div style="height: 40px"></div>

    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
      <div style="height: 20px"></div>
      <h2 class="ml-10">Diputados</h2>
      <h4 class="ml-10 font-weight-regular">Se renuevan 9 bancas</h4>
      <div style="height: 20px"></div>
      <v-slide-group multiple show-arrows :set="(provincia = 'cordoba')">
        <v-row>
          <v-col v-for="resultado in resultados2" :key="resultado.idAgrupacion">
            <v-slide-item>
              <v-card
                color="#f2f2f0"
                class="mx-auto justify-center"
                min-height="280"
                min-width="280"
                width="288"
                style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                "
              >
                <v-card-title
                  style="font-size: 14px"
                  class="justify-center mb-n4"
                >
                  <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                </v-card-title>

                <div class="text-center" style="font-size: 12px">
                  <v-list-item-avatar
                    size="120"
                    color="grey darken-6"
                    class="mx-auto d-flex"
                  >
                    <v-img
                      class="elevation-6"
                      alt=""
                      :src="cambiaNombre(resultado.idAgrupacion, provincia)[1]"
                    />
                  </v-list-item-avatar>

                  <h2 style="font-size: 20px">
                    {{ cambiaNombre(resultado.idAgrupacion, provincia)[0] }}
                  </h2>
                  <p style="font-size: 18px">
                    {{ cambiaNombre(resultado.idAgrupacion, provincia)[2] }}
                  </p>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">ver más</span>
                    </template>
                    <span v-if="resultado.idAgrupacion == 107">
                      <b>Aurelio Francisco Garcia Elorrio</b><br />
                      Memé Moscoso<br />
                      Gerardo Jose Grosso<br />
                      Paula Ponte<br />
                      Ricardo Ivan Condori<br />
                      Candela Orellana Ahumada<br />
                      Agustin Nicolas Anglada Allende<br />
                      Claudia Marcela Arguello<br />
                      Esteban Vicente Ancarani<br />
                    </span>
                    <span v-if="resultado.idAgrupacion == 114">
                      <b>Liliana Olivero</b><br />
                      Santiago Benitez Vieyra<br />
                      Luciana Gabriela Echevarria<br />
                      Jorge Luis Navarro<br />
                      Noel Argañaraz<br />
                      Gaston Vacchiani<br />
                      Soledad Cristina Diaz Garcia<br />
                      Federico Wagner<br />
                      Noelia Aguero<br />
                    </span>
                    <span v-if="resultado.idAgrupacion == 115">
                      <b>Martin Rodrigo Gill</b><br />
                      Olga Elena Riutort Lopez<br />
                      Jose Pablo Carro<br />
                      Ilda Bustos<br />
                      Natalio Osvaldo Graglia<br />
                      Francisca Mattoni<br />
                      Gaston Emanuel Mazzalay<br />
                      Graciela Susana Brarda<br />
                      Noel Gastón Tomatis<br />
                    </span>
                    <span v-if="resultado.idAgrupacion == 112">
                      <b>Natalia De La Sota</b><br />
                      Ignacio José Garcia Aresca<br />
                      Claudia Gabriela Marquez<br />
                      Sergio Sebastian Busso<br />
                      Claudia Roxana Martinez<br />
                      Gustavo Horacio Brandan<br />
                      Cristina Elizabeth Vidal<br />
                      Fabio Hernan Guaschino<br />
                      Rosalia Beatriz Caceres<br />
                    </span>
                    <span v-if="resultado.idAgrupacion == 113">
                      <b>Rodrigo De Loredo</b><br />
                      Soledad Carrizo<br />
                      Hector "La Coneja" Baldassi<br />
                      Laura Rodriguez Machado<br />
                      Gustavo Santos<br />
                      Gabriela Brouwer De Koning<br />
                      Oscar Agost Carreño<br />
                      Mariana Ispizua<br />
                      Jose Bria<br />
                    </span>
                    <span v-if="resultado.idAgrupacion == 111">
                      <b>Ruben Daniel Petetta</b><br />
                      Maria Cristina Lagger<br />
                      Juan Martin Juarez Villanueva<br />
                      Daniela Del Valle Louet<br />
                      Fernando David Funes Pireddu<br />
                      Noemi Angelica Amarillo<br />
                      Franco Matias Pierluca<br />
                      Liliana Mabel Barrera<br />
                      Jesus Damian Rustici<br />
                    </span>
                    <span v-if="resultado.idAgrupacion == 103">
                      <b>Jorge Rafael Scala</b><br />
                      Daniela Welner<br />
                      Victor Ruben Taborda<br />
                      Virginia Del Carmen Lopez<br />
                      Ariel "Trinkette" Luque<br />
                      Barbara Elena Olmos<br />
                      Abelardo Losano<br />
                      Silvia Alejandra Guzman<br />
                      Sergio Ruben Diaz<br />
                    </span>
                  </v-tooltip>

                  <!-- votos: {{ lista.votos }} -->
                  <!-- {{ resultado.idAgrupacion }} -->
                </div>

                <v-spacer />

                <v-card-actions class="ml-n2">
                  <v-progress-linear
                    :color="cambiaColor(resultado.idAgrupacion)"
                    style="position: absolute; bottom: 0"
                    :value="resultado.votosPorcentaje"
                    height="30"
                  >
                   
                      <strong style="font-size: 14px; color: #333; text-shadow: 1px 1px #fff;">{{
                        cortaTitulo(
                          cambiaNombre(resultado.idAgrupacion, provincia)[3]
                        )
                      }}
                      {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong
                    >
                  </v-progress-linear>

                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-slide-item>
          </v-col>
        </v-row>
      </v-slide-group>
      <div style="height: 20px"></div>
      <div class="ml-11" style="font-size: 13px">
         {{ poneMensaje(mesas, fecha_recuento) }} 
      </div>
      <div style="height: 15px"></div>
    </v-sheet>

    <div style="height: 40px"></div>
  </div>
</template>

<script>
import { initializeApp } from "firebase/app";
import { getDatabase, ref, child, get } from "firebase/database";
import configFirebase from "../configFirebase.js";

export default {
  name: "ModuloCardsListas",

  data() {
    return {
      resultados1: [],
      resultados2: [],
      fecha_recuento: [],
      mesas: [],
      mesa: "",
      fecha: "",
    };
  },
  mounted() {
    this.carga_datos();
    //this.carga_datos_update()
  },
  watch: {
    "$route.path": function () {
      this.carga_datos();
    },
  },
  methods: {
    carga_datos() {
      const app = initializeApp(configFirebase);
      const dbRef = ref(getDatabase(app));

      get(child(dbRef, `cordoba_s2/valoresTotalizadosPositivos`))
        .then((snapshot) => {
          this.resultados1 = snapshot.val();
        })
        .catch((error) => {
          console.error(error);
        });

      get(child(dbRef, `cordoba2/valoresTotalizadosPositivos`))
        .then((snapshot) => {
          this.resultados2 = snapshot.val();
        })
        .catch((error) => {
          console.error(error);
        });

      get(child(dbRef, `cordoba2/fechaTotalizacion`))
        .then((snapshot) => {
          //console.log(snapshot.val());
          this.fecha_recuento = snapshot.val();
        })
        .catch((error) => {
          console.error(error);
        });

      get(child(dbRef, `cordoba2/estadoRecuento/mesasTotalizadasPorcentaje`))
        .then((snapshot) => {
          this.mesas = snapshot.val();
        })
        .catch((error) => {
          console.error(error);
        });
      return "";
    },
    carga_datos_update: function () {
      setInterval(() => {
        this.carga_datos();
      }, 60000);
    },
    cortaTitulo(agrupacion) {
      let nagrupacion = "";
      switch (agrupacion) {
        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
          nagrupacion = "FIT - UNIDAD";
          break;
        case "CORRIENTE DE PENSAMIENTO BONAERENSE":
          nagrupacion = "CTE DE PENSAMIENTO BONAERENSE";
          break;
        case "CHACO CAMBIA + JUNTOS POR EL CAMBIO":
          nagrupacion = "CHACO CAMBIA + JXC";
          break;
        case "COALICIÓN CÍVICA - AFIRMACIÓN PARA UNA REPÚBLICA IGUALITARIA (ARI)":
          nagrupacion = "COALICIÓN CÍVICA - ARI";
          break;
        case "SOMOS ENERGÍA PARA RENOVAR SANTA CRUZ -SER-":
          nagrupacion = "SOMOS ENERGÍA P R SANTA CRUZ";
          break;
        case "JUNTOS POR EL CAMBIO TIERRA DEL FUEGO":
          nagrupacion = "JXC TIERRA DEL FUEGO";
          break;
        case "ENCUENTRO VECINAL CÓRDOBA":
          nagrupacion = "ENC. VECINAL CÓRDOBA";
          break;
        default:
          nagrupacion = agrupacion;
          break;
      }
      return nagrupacion;
    },
    poneMensaje(total, fecha) {
      let totalMensaje = "";
      if (fecha != "") {
        return (totalMensaje = `Mesas escrutadas: ${total}% actualizado: ${new Date(
          fecha
        ).toLocaleTimeString()}`);
      }
      return (totalMensaje = "Cargando datos");
    },

    cambiaNombre(nombre, provincia) {
      let agrupacion = "";
      let candidato = "";
      let candidato2 = "";
      let fotoLista = "";

      //console.log(nombre);
      //buenos aires

      switch (provincia) {
        case "cordoba":
          /*cordoba*/
          switch (nombre) {
            case "107":
              agrupacion = "ENCUENTRO VECINAL CÓRDOBA";
              candidato = "Aurelio Garcia Elorrio";
              candidato2 = "Memé Moscoso";
              fotoLista = "/img/cordoba_aurelio_garcia_elorrio.jpg";
              break;

            case "114":
              agrupacion = "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD";
              candidato = "Liliana Olivero";
              candidato2 = "Santiago Benitez Vieyra";
              fotoLista = "/img/cordoba_liliana_olivero.jpg";
              break;

            case "115":
              agrupacion = "FRENTE DE TODOS";
              candidato = "Martin Rodrigo Gill";
              candidato2 = "Olga Elena Riutort Lopez";
              fotoLista = "/img/cordoba_martin_rodrigo_gill.jpg";
              break;

            case "112":
              agrupacion = "HACEMOS POR CÓRDOBA";
              candidato = "Natalia De La Sota";
              candidato2 = "Ignacio José Garcia Aresca";
              fotoLista = "/img/cordoba_natalia_de_la_sota.jpg";
              break;

            case "113":
              agrupacion = "JUNTOS POR EL CAMBIO";
              candidato = "Rodrigo De Loredo";
              candidato2 = "Soledad Carrizo";
              fotoLista = "/img/cordoba_rodrigo_de_loredo.jpg";
              break;

            case "111":
              agrupacion = "LA LIBERTAD AVANZA";
              candidato = "Ruben Daniel Petetta";
              candidato2 = "Maria Cristina Lagger";
              fotoLista = "/img/cordoba_ruben_daniel_petetta.jpg";
              break;

            case "103":
              agrupacion = "UNIÓN POPULAR FEDERAL";
              candidato = "Jorge Rafael Scala";
              candidato2 = "Daniela Welner";
              fotoLista = "/img/cordoba_jorge_rafael_scala.jpg";
              break;
          }
          break;

        case "cordoba_s":
          switch (nombre) {
            case "107":
              agrupacion = "ENCUENTRO VECINAL CÓRDOBA";
              candidato = "Juan Pablo Quinteros";
              candidato2 = "Elena Maria De Las Mercedes Vacchetta";
              fotoLista = "/img/cordoba_juan_pablo_quinteros.jpg";
              break;
            case "114":
              agrupacion = "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD";
              candidato = "Laura Vilches";
              candidato2 = "Alfredo Leytes";
              fotoLista = "/img/cordoba_laura_vilches.jpg";
              break;
            case "115":
              agrupacion = "FRENTE DE TODOS";
              candidato = "Carlos Alberto Caserio";
              candidato2 = "Gabriela Beatriz Estevez";
              fotoLista = "/img/cordoba_carlos_alberto_caserio.jpg";
              break;
            case "112":
              agrupacion = "HACEMOS POR CÓRDOBA";
              candidato = "Alejandra Maria Vigo";
              candidato2 = "Eduardo Luis Accastello";
              fotoLista = "/img/cordoba_alejandra_maria_vigo.jpg";
              break;
            case "113":
              agrupacion = "JUNTOS POR EL CAMBIO";
              candidato = "Luis Juez";
              candidato2 = "Carmen Alvarez";
              fotoLista = "/img/cordoba_luis_juez.jpg";
              break;
            case "111":
              agrupacion = "LA LIBERTAD AVANZA";
              candidato = "Maria Eugenia Gordillo";
              candidato2 = "Enrique Jose Rigatuso";
              fotoLista = "/img/cordoba_maria_eugenia_gordillo.jpg";
              break;
            case "103":
              agrupacion = "UNIÓN POPULAR FEDERAL";
              candidato = "Agustin Alejandro Spaccesi";
              candidato2 = "Ana Gabriela Nemer Pelliza";
              fotoLista = "/img/cordoba_agustin_alejandro_spaccesi.jpg";
              break;
          }
          break;

        default:
          candidato = nombre;
          candidato2 = "";

          break;
      }

      if (fotoLista == "") {
        fotoLista = "/img/persona.png";
      }
      return [candidato, fotoLista, candidato2, agrupacion];
      // alert(nombre);
    },

    verifica_porcenteje(porcentaje_partido) {
      if (porcentaje_partido == 100) {
        return "";
      } else {
        return porcentaje_partido + " %";
      }
    },

    cambiaColor(idAgrupacion) {
      let agrupacionColor = "";
      switch (idAgrupacion) {
        case "114":
          agrupacionColor = "rgba(233, 23, 48)"; //FIT
          break;

        case "115":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
          break;

        case "113":
          agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
          break;

        default:
          agrupacionColor = "rgba(140, 147, 156)";
          break;
      }
      return agrupacionColor;
    },
  },
};
</script>
