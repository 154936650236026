<template>
  <div id="app">
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
      <div style="height: 20px"></div>
      <h2 class="ml-10">Diputados</h2>
      <h4 class="ml-10 font-weight-regular">Se renuevan 3 bancas</h4>
      <div style="height: 20px"></div>
      <v-slide-group multiple show-arrows :set="(provincia = 'misiones')">
        <v-row>
          <v-col v-for="resultado in resultados" :key="resultado.idAgrupacion">
            <v-slide-item>
              <v-card
                color="#f2f2f0"
                class="mx-auto justify-center"
                min-height="280"
                min-width="280"
                width="288"
                style="background: rgb(232, 232, 232); background: linear-gradient(140deg, rgba(232, 232, 232, 1) 0%, rgba(229, 229, 238, 1) 28%, rgba(255, 255, 255, 1) 100%);">
                <v-card-title
                  style="font-size: 14px"
                  class="justify-center mb-n4"
                >
                  <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                </v-card-title>

                <div class="text-center" style="font-size: 12px">
                  <v-list-item-avatar
                    size="120"
                    color="grey darken-6"
                    class="mx-auto d-flex"
                  >
                    <v-img
                      class="elevation-6"
                      alt=""
                      :src="cambiaNombre(resultado.idAgrupacion, provincia)[1]"
                    />
                  </v-list-item-avatar>

                  <h2 style="font-size: 20px">
                    {{ cambiaNombre(resultado.idAgrupacion, provincia)[0] }}
                  </h2>
                  <p style="font-size: 18px">
                    {{ cambiaNombre(resultado.idAgrupacion, provincia)[2] }}
                  </p>
                  <!-- votos: {{ lista.votos }} -->
                  <!-- {{ resultado.idAgrupacion }} -->
                </div>

                <v-spacer />

                <v-card-actions class="ml-n2">
                  <v-progress-linear
                    :color="cambiaColor(resultado.idAgrupacion)"
                    style="position: absolute; bottom: 0"
                    :value="resultado.votosPorcentaje"
                    height="30"
                  >
                   
                      <strong style="font-size: 14px; color: #333; text-shadow: 1px 1px #fff;">{{
                        cortaTitulo(
                          cambiaNombre(resultado.idAgrupacion, provincia)[3]
                        )
                      }}
                      {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong
                    >
                  </v-progress-linear>

                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-slide-item>
          </v-col>
        </v-row>
      </v-slide-group>
      <div style="height: 20px"></div>
      <div class="ml-11" style="font-size: 13px">
         {{ poneMensaje(mesas, fecha_recuento) }} 
      </div>
      <div style="height: 15px"></div>
    </v-sheet>

    <div style="height: 40px"></div>
  </div>
</template>

<script>
import { initializeApp } from "firebase/app";
import { getDatabase, ref, child, get } from "firebase/database";
import configFirebase from "../configFirebase.js";

export default {
  name: "ModuloCardsListas",

  data() {
    return {
      resultados: [],
      fecha_recuento: [],
      mesas: [],
      mesa: "",
      fecha: "",
    };
  },
  mounted() {
    this.carga_datos();
    //this.carga_datos_update()
  },
  watch: {
    "$route.path": function () {
      this.carga_datos();
    },
  },
  methods: {
    carga_datos() {
      const app = initializeApp(configFirebase);
      const dbRef = ref(getDatabase(app));

      get(child(dbRef, `misiones2/valoresTotalizadosPositivos`))
        .then((snapshot) => {
          this.resultados = snapshot.val();
        })
        .catch((error) => {
          console.error(error);
        });

      get(child(dbRef, `misiones2/fechaTotalizacion`))
        .then((snapshot) => {
          //console.log(snapshot.val());
          this.fecha_recuento = snapshot.val();
        })
        .catch((error) => {
          console.error(error);
        });

      get(child(dbRef, `misiones2/estadoRecuento/mesasTotalizadasPorcentaje`))
        .then((snapshot) => {
          this.mesas = snapshot.val();
        })
        .catch((error) => {
          console.error(error);
        });
      return "";
    },
    carga_datos_update: function () {
      setInterval(() => {
        this.carga_datos();
      }, 60000);
    },
    cortaTitulo(agrupacion) {
      let nagrupacion = "";
      switch (agrupacion) {
        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
          nagrupacion = "FIT - UNIDAD";
          break;
        case "CORRIENTE DE PENSAMIENTO BONAERENSE":
          nagrupacion = "CTE DE PENSAMIENTO BONAERENSE";
          break;
        case "CHACO CAMBIA + JUNTOS POR EL CAMBIO":
          nagrupacion = "CHACO CAMBIA + JXC";
          break;
        case "COALICIÓN CÍVICA - AFIRMACIÓN PARA UNA REPÚBLICA IGUALITARIA (ARI)":
          nagrupacion = "COALICIÓN CÍVICA - ARI";
          break;
        case "SOMOS ENERGÍA PARA RENOVAR SANTA CRUZ -SER-":
          nagrupacion = "SOMOS ENERGÍA P R SANTA CRUZ";
          break;
        case "JUNTOS POR EL CAMBIO TIERRA DEL FUEGO":
          nagrupacion = "JXC TIERRA DEL FUEGO";
          break;
        case "FRENTE JUNTOS POR EL CAMBIO":
          nagrupacion = "FTE. JUNTOS POR EL CAMBIO";
          break;
          case "FRENTE RENOVADOR DE LA CONCORDIA":
            nagrupacion = "FTE. R. DE LA CONCORDIA";
            break;
          case "LIBERTAD, VALORES Y CAMBIO":
            nagrupacion = "L, VALORES Y CAMBIO";
            break;

        default:
          nagrupacion = agrupacion;
          break;
      }
      return nagrupacion;
    },
    poneMensaje(total, fecha) {
      let totalMensaje = "";
      if (fecha != "") {
        return (totalMensaje = `Mesas escrutadas: ${total}% actualizado: ${new Date(
          fecha
        ).toLocaleTimeString()}`);
      }
      return (totalMensaje = "Cargando datos");
    },

    cambiaNombre(nombre, provincia) {
      let agrupacion = "";
      let candidato = "";
      let candidato2 = "";
      let fotoLista = "";

      //console.log(nombre);
      //buenos aires

      switch (provincia) {
        case "misiones":
          /*misiones*/
          switch (nombre) {
            case "172":
              agrupacion = "DEL OBRERO";
              candidato = "Eduardo D. Cantero";
              candidato2 = "L. Virginia Villanueva";
              fotoLista = "/img/misiones_eduardo_cantero.jpg";
              break;

            case "176":
              agrupacion = "FRENTE DE TODOS";
              candidato = "Pablo Isaac Lenguaza";
              candidato2 = "Graciela B. De Melo";
              fotoLista = "/img/misiones_pablo_isaac_lenguaza.jpg";
              break;

            case "174":
              agrupacion = "FRENTE JUNTOS POR EL CAMBIO";
              candidato = "Martin A. Arjol";
              candidato2 = "Florencia Klipauka Lewtak";
              fotoLista = "/img/misiones_martin_arjol.jpg";
              break;

            case "175":
              agrupacion = "FRENTE RENOVADOR DE LA CONCORDIA";
              candidato = "Carlos Alberto Fernandez";
              candidato2 = "Claudia Noemi Gauto";
              fotoLista = "/img/misiones_carlos_alberto_fernandez.jpg";
              break;

            case "173":
              agrupacion = "LIBERTAD, VALORES Y CAMBIO";
              candidato = "Ninfa Zunilda Alvarenga";
              candidato2 = "Mauricio Rolando Czerevin";
              fotoLista = "/img/misiones_ninfa_zunilda alvarenga.jpg";
              break;
          }
          break;

        default:
          candidato = nombre;
          candidato2 = "";

          break;
      }

      if (fotoLista == "") {
        fotoLista = "/img/persona.png";
      }
      return [candidato, fotoLista, candidato2, agrupacion];
      // alert(nombre);
    },

    verifica_porcenteje(porcentaje_partido) {
      if (porcentaje_partido == 100) {
        return "";
      } else {
        return porcentaje_partido + " %";
      }
    },

    cambiaColor(idAgrupacion) {
      let agrupacionColor = "";
      switch (idAgrupacion) {
        case "176":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
          break;

        default:
          agrupacionColor = "rgba(140, 147, 156)";
          break;
      }
      return agrupacionColor;
    },
  },
};
</script>
