<template>
  <div id="app">
    <v-sheet class="mx-auto" elevation="2" max-width="1600" min-height="380">
      <div style="height: 20px"></div>
      <h2 class="ml-10">Diputados</h2>
      <h4 class="ml-10 font-weight-regular">Se renuevan 13 bancas</h4>
      <div style="height: 20px"></div>
      <v-slide-group multiple show-arrows :set="(provincia = 'caba')">
        <v-row>
          <v-col v-for="resultado in resultados" :key="resultado.idAgrupacion">
            <v-slide-item>
              <v-card
                color="#f2f2f0"
                class="mx-auto justify-center"
                min-height="280"
                min-width="280"
                width="288"
                style="
                  background: rgb(232, 232, 232);
                  background: linear-gradient(
                    140deg,
                    rgba(232, 232, 232, 1) 0%,
                    rgba(229, 229, 238, 1) 28%,
                    rgba(255, 255, 255, 1) 100%
                  );
                "
              >
                <v-card-title
                  style="font-size: 14px"
                  class="justify-center mb-n4"
                >
                  <!-- {{ cortaTitulo(resultado.nombreAgrupacion) }} -->
                </v-card-title>

                <div class="text-center" style="font-size: 12px">
                  <v-list-item-avatar
                    size="120"
                    color="grey darken-6"
                    class="mx-auto d-flex"
                  >
                    <v-img
                      class="elevation-6"
                      alt=""
                      :src="cambiaNombre(resultado.idAgrupacion, provincia)[1]"
                    />
                  </v-list-item-avatar>

                  <h2 style="font-size: 20px">
                    {{ cambiaNombre(resultado.idAgrupacion, provincia)[0] }}
                  </h2>
                  <p style="font-size: 18px">
                    {{ cambiaNombre(resultado.idAgrupacion, provincia)[2] }}
                  </p>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">ver más</span>
                    </template>
                    <span v-if="resultado.idAgrupacion == 14">
                      <b>Javier Gerardo Milei</b><br />
                      Victoria Eugenia Villarruel<br />
                      Nicolas Mario Emma<br />
                      Maria Fernanda Araujo<br />
                      Carlos Gustavo Durini<br />
                      Alicia Chediack<br />
                      Guillermo Heriberto Mac Louglin<br />
                      Clara Ines Malbran<br />
                      Santiago Javier Santurio Rodriguez<br />
                      Alejandra Nieves Evaristo<br />
                      Gaston Lucas Alberdi Peña Vera<br />
                      Rosana Maria Loto<br />
                      Diego Orlando Spagnuolo<br />
                    </span>
                    <span v-if="resultado.idAgrupacion == 7">
                      <b>Luis Fernando Zamora</b><br />
                      Virginia Florencia Peyras Villarino<br />
                      Fernando Pablo Vilardo<br />
                      Alejandra Gabriela Fava Castro<br />
                      Sergio Adrian Sallustio<br />
                      Maria Jose Dalia<br />
                      Facundo Alberto Sandro Macchi<br />
                      Diana Andrea Ludueña<br />
                      Gustavo Javier Pedulla<br />
                      Maria Victoria Amerio<br />
                      Hugo Marcial Benitez Escurra<br />
                      Mariela Judith Milstein<br />
                      Federico Carlevaro<br />
                    </span>
                    <span v-if="resultado.idAgrupacion == 13">
                      <b>Myriam Teresa Bregman</b><br />
                      Fernando Sebastián Ramal<br />
                      Vanina Natalia Biasi<br />
                      Jorge Daniel Adaro<br />
                      Maria De Las Mercedes De Mendieta<br />
                      Patricio Del Corro<br />
                      Maria Celeste Fierro<br />
                      Facundo Ariel Miño<br />
                      Andrea Iris D'Atri<br />
                      Jose Ernesto Castillo<br />
                      Ileana Amanda Elena Lidia Celotto<br />
                      Pablo Martin Anino<br />
                      Laura Enda Marrone<br />
                    </span>
                    <span v-if="resultado.idAgrupacion == 12">
                      <b>Leandro Jorge Santoro</b><br />
                      Maria Gisela Marziotta<br />
                      Carlos Salomon Heller<br />
                      Lorena Iris Pokoik Garcia<br />
                      Matias Raul Tombolini<br />
                      Maria Cecilia Barros<br />
                      Claudio Raul Lozano<br />
                      Jacqueline Andrea Flores<br />
                      Roberto Claudio Pianelli<br />
                      Maria De La Cruz Rachid<br />
                      Victor Jose Colombano<br />
                      Magali Sol Fernandez<br />
                      Marcelo Hector Orlando<br />
                    </span>
                    <span v-if="resultado.idAgrupacion == 11">
                      <b>Maria Eugenia Vidal</b><br />
                      Martin Alberto Tetaz<br />
                      Paula Mariana Oliveto Lago<br />
                      Ricardo Hipolito Lopez Murphy<br />
                      Ana Carla Carrizo<br />
                      Fernando Adolfo Iglesias<br />
                      Sabrina Carlota Ajmechet<br />
                      Fernando Sanchez<br />
                      Sandra Irene Pitta Alvarez<br />
                      Pablo Hector Walter<br />
                      Sandra Claudia Ruiz<br />
                      Gustavo Daniel Lazzari<br />
                      Florencia Teuly<br />
                    </span>
                  </v-tooltip>

                  <!-- votos: {{ lista.votos }} -->
                  <!-- {{ resultado.idAgrupacion }} -->
                </div>

                <v-spacer />

                <v-card-actions class="ml-n2">
                  <v-progress-linear
                    :color="cambiaColor(resultado.idAgrupacion)"
                    style="position: absolute; bottom: 0"
                    :value="resultado.votosPorcentaje"
                    height="30"
                  >
                  
                      <strong style="font-size: 14px; color: #333; text-shadow: 1px 1px #fff;">{{
                        cortaTitulo(
                          cambiaNombre(resultado.idAgrupacion, provincia)[3]
                        )
                      }}
                      {{
                        verifica_porcenteje(resultado.votosPorcentaje)
                      }}</strong
                    >
                  </v-progress-linear>

                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-slide-item>
          </v-col>
        </v-row>
      </v-slide-group>
      <div style="height: 20px"></div>
      <div class="ml-11" style="font-size: 13px">
         {{ poneMensaje(mesas, fecha_recuento) }} 
      </div>
      <div style="height: 15px"></div>
    </v-sheet>

    <div style="height: 40px"></div>
  </div>
</template>

<script>
import { initializeApp } from "firebase/app";
import { getDatabase, ref, child, get } from "firebase/database";
import configFirebase from "../configFirebase.js";

export default {
  name: "ModuloCardsListas",

  data() {
    return {
      resultados: [],
      fecha_recuento: [],
      mesas: [],
      mesa: "",
      fecha: "",
    };
  },
  mounted() {
    this.carga_datos();
    //this.carga_datos_update()
  },
  watch: {
    "$route.path": function () {
      this.carga_datos();
    },
  },
  methods: {
    carga_datos() {
      //console.log("cargando caba");
      const app = initializeApp(configFirebase);
      const dbRef = ref(getDatabase(app));

      get(child(dbRef, `caba2/valoresTotalizadosPositivos`))
        .then((snapshot) => {
          this.resultados = snapshot.val();
        })
        .catch((error) => {
          console.error(error);
        });

      get(child(dbRef, `caba2/fechaTotalizacion`))
        .then((snapshot) => {
          //console.log(snapshot.val());
          this.fecha_recuento = snapshot.val();
        })
        .catch((error) => {
          console.error(error);
        });

      get(child(dbRef, `caba2/estadoRecuento/mesasTotalizadasPorcentaje`))
        .then((snapshot) => {
          this.mesas = snapshot.val();
        })
        .catch((error) => {
          console.error(error);
        });
      return "";
    },
    carga_datos_update: function () {
      setInterval(() => {
        this.carga_datos();
      }, 60000);
    },
    cortaTitulo(agrupacion) {
      let nagrupacion = "";
      switch (agrupacion) {
        case "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD":
          nagrupacion = "FIT - UNIDAD";
          break;
        case "CORRIENTE DE PENSAMIENTO BONAERENSE":
          nagrupacion = "CTE DE PENSAMIENTO BONAERENSE";
          break;
        case "CHACO CAMBIA + JUNTOS POR EL CAMBIO":
          nagrupacion = "CHACO CAMBIA + JXC";
          break;
        case "COALICIÓN CÍVICA - AFIRMACIÓN PARA UNA REPÚBLICA IGUALITARIA (ARI)":
          nagrupacion = "COALICIÓN CÍVICA - ARI";
          break;
        case "SOMOS ENERGÍA PARA RENOVAR SANTA CRUZ -SER-":
          nagrupacion = "SOMOS ENERGÍA P R SANTA CRUZ";
          break;
        case "JUNTOS POR EL CAMBIO TIERRA DEL FUEGO":
          nagrupacion = "JXC TIERRA DEL FUEGO";
          break;
        case "AUTODETERMINACIÓN Y LIBERTAD":
          nagrupacion = "AUT. Y LIBERTAD";
          break;
        default:
          nagrupacion = agrupacion;
          break;
      }
      return nagrupacion;
    },
    poneMensaje(total, fecha) {
      let totalMensaje = "";
      if (fecha != "") {
        return (totalMensaje = `Mesas escrutadas: ${total}% actualizado: ${new Date(
          fecha
        ).toLocaleTimeString()}`);
      }
      return (totalMensaje = "Cargando datos");
    },

    cambiaNombre(nombre, provincia) {
      let agrupacion = "";
      let candidato = "";
      let candidato2 = "";
      let fotoLista = "";

      //console.log(nombre);
      //buenos aires

      switch (provincia) {
        case "caba":
          /*caba*/
          switch (nombre) {
            case "14":
              agrupacion = "LA LIBERTAD AVANZA";
              candidato = "Javier Gerardo Milei";
              candidato2 = "Victoria Eugenia Villarruel";
              fotoLista = "/img/caba_javier_milei.jpg";
              break;

            case "7":
              agrupacion = "AUTODETERMINACIÓN Y LIBERTAD";
              candidato = "Luis Fernando Zamora";
              candidato2 = "Virginia Florencia Peyras Villarino";
              fotoLista = "/img/caba_luis_zamora.jpg";
              break;

            case "13":
              agrupacion = "FRENTE DE IZQUIERDA Y DE TRABAJADORES - UNIDAD";
              candidato = "Myriam Teresa Bregman";
              candidato2 = "Fernando Sebastián Ramal";
              fotoLista = "/img/caba_myriam_bregman.jpg";
              break;

            case "12":
              agrupacion = "FRENTE DE TODOS";
              candidato = "Leandro Jorge Santoro";
              candidato2 = "Maria Gisela Marziotta";
              fotoLista = "/img/caba_leandro_santoro.jpg";
              break;

            case "11":
              agrupacion = "JUNTOS POR EL CAMBIO";
              candidato = "Maria Eugenia Vidal";
              candidato2 = "Martin Alberto Tetaz";
              fotoLista = "/img/caba_maria_eugenia_vidal.jpg";
              break;
          }
          break;

        default:
          candidato = nombre;
          candidato2 = "";

          break;
      }

      if (fotoLista == "") {
        fotoLista = "/img/persona.png";
      }
      return [candidato, fotoLista, candidato2, agrupacion];
      // alert(nombre);
    },

    verifica_porcenteje(porcentaje_partido) {
      if (porcentaje_partido == 100) {
        return "";
      } else {
        return porcentaje_partido + " %";
      }
    },

    cambiaColor(idAgrupacion) {
      let agrupacionColor = "";
      switch (idAgrupacion) {
        case "7":
          agrupacionColor = "rgba(89, 46, 107)";
          break;
        case "14":
          agrupacionColor = "rgba(78, 102, 173)";
          break;
        case "13":
          agrupacionColor = "rgba(233, 23, 48)"; //FIT
          break;
        case "12":
          agrupacionColor = "rgba(0,186,242,1)"; //FRENTE DE TODOS
          break;
        case "11":
          agrupacionColor = "rgb(228, 114, 9)"; // JUNTOS POR EL CAMBIO
          break;
        default:
          agrupacionColor = "rgba(140, 147, 156)";
          break;
      }
      return agrupacionColor;
    },
  },
};
</script>
